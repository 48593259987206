import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Photo } from 'src/app/models/Photo';
import { Location } from '@angular/common';
import { PhotoService } from 'src/app/services/photo.service';
import { ActivatedRoute } from '@angular/router';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit, AfterViewInit, OnDestroy {
  gallery: any;
  id: any;
  contentLoaded = false;
  gallerySubscription: any;

  gallerySwiperConfig: SwiperConfigInterface = {
    direction: 'vertical',
    speed: 400,
    effect: 'fade',
    slidesPerView: 1,
    loop: true,
    mousewheel: {
      sensitivity: 99999999,
      releaseOnEdges: true
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true
    }
  };

  constructor(private route: ActivatedRoute, private photoService: PhotoService,
    private title: Title) {
      this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.getGallery();
  }

  ngAfterViewInit() {
    this.contentLoaded = true;
    this.title.setTitle(this.gallery.name);
  }

  ngOnDestroy() {
    this.gallerySubscription.unsubscribe();
  }

  getGallery(): void {
    this.gallerySubscription = this.photoService.getGallery(this.id)
      .subscribe(gallery => this.gallery = gallery);
  }
}
