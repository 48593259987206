import { Injectable } from '@angular/core';
import { About } from '../models/About';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  aboutUrl = '/.netlify/functions/getAbout';
  aboutArray: any;

  constructor(private http: HttpClient) { }

  getAllPages(): Observable<About[]> {
    this.aboutArray = this.http.get<About[]>(this.aboutUrl, {
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return this.aboutArray;
  }

  getContacts(id: string): Observable<About> {
    return this.getAllPages().pipe(map(pages => pages.find(page => page.title === id)));
  }
}
