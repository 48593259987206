import {
    transition,
    trigger,
    query,
    style,
    animate,
    group,
    sequence,
    stagger,
    animateChild
 } from '@angular/animations';
 export const slideInAnimation =
    trigger('routeAnimations', [
         transition('Home => *', [
              query(':enter, :leave',
                   style({ position: 'absolute',  width: '100%' }),
                   { optional: true }),
                   sequence([
                   group([
                   query(':leave .grassTop', [
                        style({ bottom: '-9vh' }),
                        animate('0.2s ease-in-out',
                        style({ bottom: '-20vh' }))
                    ], { optional: true }),
                    query(':leave .grassBack', [
                        style({ bottom: '-15vh' }),
                        animate('0.3s ease-in-out',
                        style({ bottom: '-50vh' }))
                    ], { optional: true }),
                    query(':leave .flower', [
                        style({ bottom: '-10vh', right: '-2vh' }),
                        animate('0.3s ease-in-out',
                        style({ bottom: '-30vh', right: '-30vh' }))
                    ], { optional: true }),
                    query(':leave', [
                        style({ opacity: '1' }),
                        animate('0.3s ease-in-out',
                        style({ opacity: '0' }))
                    ], { optional: true }),
                    query(':enter', [
                        style({ opacity: '0' }),
                        animate('0.3s ease-in-out',
                        style({ opacity: '1' }))
                    ], { optional: true }),
            ])
        ])
         ]),
         transition('PreviousA => *', [
               query(':enter, :leave',
                   style({ position: 'fixed', width: '100%' }),
                   { optional: true }),
               group([
                query(':leave', [
                    style({ opacity: '1' }),
                    animate('0.3s ease-in-out',
                    style({ opacity: '0' }))
                ], { optional: true }),
                query(':enter', [
                    style({ opacity: '0' }),
                    animate('0.3s ease-in-out',
                    style({ opacity: '1' }))
                ], { optional: true }),
               ])
        ]),
        transition('PreviousB => *', [
            query(':enter, :leave',
                style({ position: 'fixed', width: '100%' }),
                { optional: true }),
            group([
             query(':leave', [
                 style({ opacity: '1' }),
                 animate('0.3s ease-in-out',
                 style({ opacity: '0' }))
             ], { optional: true }),
             query(':enter', [
                 style({ opacity: '0' }),
                 animate('0.3s ease-in-out',
                 style({ opacity: '1' }))
             ], { optional: true }),
            ])
     ]),
     transition('PreviousC => *', [
        query(':enter, :leave',
            style({ position: 'absolute', width: '100%' }),
            { optional: true }),
        group([
         query(':leave', [
             style({ opacity: '1' }),
             animate('0.3s ease-in-out',
             style({ opacity: '0' }))
         ], { optional: true }),
         query(':enter', [
             style({ opacity: '0' }),
             animate('0.3s ease-in-out',
             style({ opacity: '1' }))
         ], { optional: true }),
        ])
 ]),
 ]);
