import { Injectable } from '@angular/core';
import { Social } from '../models/Social';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class SocialService {
  constructor(private http: HttpClient) { }

  getSocial(): Observable<Social[]> {
    return this.http.get<Social[]>('/.netlify/functions/getSocial', {
      headers: {
        'Content-Type': 'application/json'
      },
    });
  }
}
