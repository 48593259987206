import { Component, Input, HostListener, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VideosService } from 'src/app/services/videos.service';
import { Video } from 'src/app/models/Video';
import { Feature } from 'src/app/models/Feature';
import { Location } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoComponent implements AfterViewInit, OnDestroy {
  videos: Video[];
  features: Feature[];
  video: any;
  id: any;
  isVimeo: any;
  isVideo: any;
  videoOn = true;
  screenshotsOn = false;
  creditsOn = false;
  contentLoaded = false;
  moreVideosVisible = false;
  moreVideosLoading = false;

  maxCharacters = 30;

  videosSubscription: any;
  featuresSubscription: any;

  viewportWidth = window.innerWidth;

  constructor( private route: ActivatedRoute, private title: Title,
    private videoService: VideosService) {
      route.params.subscribe(val => {
        window.scrollTo(0, 0);
        const outerThis = this;
        this.videoOn = true;
          this.screenshotsOn = false;
          this.creditsOn = false;
        this.id = this.route.snapshot.paramMap.get('id');
      if (/feature/.test(window.location.href)) {
        this.isVideo = false;
      } else if (/video/.test(window.location.href)) {
        this.isVideo = true;
      }
        if (this.isVideo) {
          this.getVideos();
        } else {
          this.getFeatures();
        }
        if (this.viewportWidth < 371) {
          this.maxCharacters = 25;
        } else {
          this.maxCharacters = 30;
        }
      });
    }

  @ViewChild('header') header: ElementRef;
    @ViewChild('videoName') videoName: ElementRef;
    @ViewChild('videoNav') videoNav: ElementRef;
    @ViewChild('backToMain') backToMain: ElementRef;
    @ViewChild('logo') logo: ElementRef;
  @ViewChild('introSection') introSection: ElementRef;
    @ViewChild('downButton') downButton: ElementRef;
  @ViewChild('videoSection') videoSection: ElementRef;
  @ViewChild('screenshotsSection') screenshotsSection: ElementRef;
  @ViewChild('creditsSection') creditsSection: ElementRef;
  @ViewChild('showMoreButton') showMoreButton: ElementRef;

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
      if (this.videoSection) {
      const videoPosition = this.videoSection.nativeElement.getBoundingClientRect();
      if (this.viewportWidth < 1025) {
        const introPosition = this.introSection.nativeElement.getBoundingClientRect();
        if (introPosition.top <= - 36) {
          this.downButton.nativeElement.classList.add('goDown');
          this.backToMain.nativeElement.classList.add('goLeft');
            this.videoOn = true;
            this.screenshotsOn = false;
            this.creditsOn = false;
        } else {
          this.downButton.nativeElement.classList.remove('goDown');
          this.backToMain.nativeElement.classList.remove('goLeft');
        }
        if (introPosition.top >= (-introPosition.height + 2)) {
          this.videoName.nativeElement.classList.add('desktopIB');
          this.videoNav.nativeElement.classList.add('desktop');
          this.logo.nativeElement.classList.remove('desktop');
          this.header.nativeElement.classList.remove('contentOn');
        } else if (introPosition.top >= introPosition.top) {
          this.videoName.nativeElement.classList.remove('desktopIB');
          this.videoNav.nativeElement.classList.remove('desktop');
          this.logo.nativeElement.classList.add('desktop');
          this.header.nativeElement.classList.add('contentOn');
        }
      } else if (this.viewportWidth > 1024) {
        if (videoPosition.top < 18) {
          this.videoOn = true;
          this.screenshotsOn = false;
          this.creditsOn = false;
        }
      }
      if (this.video.screenshots) {
        const screenshotsPosition = this.screenshotsSection.nativeElement.getBoundingClientRect();
        if (screenshotsPosition.top <= 18 && (screenshotsPosition.bottom > videoPosition.height)) {
          this.videoOn = false;
          this.screenshotsOn = true;
          this.creditsOn = false;
        } else if ((screenshotsPosition.bottom < videoPosition.height) && this.video.credits) {
          this.videoOn = false;
          this.screenshotsOn = false;
          this.creditsOn = true;
        }
      }
      if (this.video.credits && !this.video.screenshots) {
        const creditsPosition = this.creditsSection.nativeElement.getBoundingClientRect();
        if (creditsPosition.top <= 18) {
          this.videoOn = false;
          this.screenshotsOn = false;
          this.creditsOn = true;
        }
      }
    }
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight;
    if (pos >= (max - 10) && this.showMoreButton)   {
      this.showMoreButton.nativeElement.classList.add('slideUp'); } else if (this.showMoreButton) {
        this.showMoreButton.nativeElement.classList.remove('slideUp');
      }
    }

    @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.viewportWidth = window.innerWidth;
    if (this.viewportWidth > 1024) {
      this.header.nativeElement.classList.remove('contentOn');
      this.logo.nativeElement.classList.remove('desktop');
      this.logo.nativeElement.classList.remove('desktopIB');
    }
    if (this.viewportWidth < 371) {
      this.maxCharacters = 25;
    } else {
      this.maxCharacters = 30;
    }
  }

  ngAfterViewInit() {
   this.contentLoaded = true;
  }

  ngOnDestroy() {
    if (this.isVideo) {
      this.videosSubscription.unsubscribe();
    } else {
      this.featuresSubscription.unsubscribe();
    }
  }

  onIntersection(id) {
      const el = document.getElementById(id);
      const dimensions = el.getBoundingClientRect();
      if (dimensions.top < 0) {
      el.scrollIntoView({ behavior: 'smooth'});
      }
  }

  showMoreVideos() {
    this.moreVideosLoading = true;
    const outerThis = this;
    setTimeout(
      function() {
        outerThis.moreVideosVisible = true;
        outerThis.moreVideosLoading = false;
      }, 1500
      );
      setTimeout(
        function() {
          outerThis.scrollTo('moreVideos', event);
        }, 1700
        );
  }

  scrollTo(id, event) {
    const el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth'});
  }

  selectVideo(event) {
    event.stopPropagation();
    event.target.parentNode.classList.add('activeVideo');
    event.target.parentNode.parentNode.parentNode.classList.add('videoSelected');
  }

  deselectVideo() {
    const selected = document.getElementsByClassName('activeVideo');
    const selectedTop = document.getElementsByClassName('videoSelected');
    [].forEach.call(selected, function(el) {
      el.classList.remove('activeVideo');
    });
    [].forEach.call(selectedTop, function(el) {
      el.classList.remove('videoSelected');
    });
  }

  getVideo(): void {
    if (this.isVideo) {
    this.video = this.videos.find(video => video.id.current === this.id);
  } else {
    this.video = this.features.find(video => video.id.current === this.id);
  }
  if (this.video.trailer && this.video.trailer.includes('vimeo')) {
    this.isVimeo = true;
  } else {
    this.isVimeo = false;
  }
  }

  getVideos(): void {
    this.videosSubscription = this.videoService.getAllVideos()
        .subscribe(videos => this.videos = videos,
          () => {},
          () => this.getVideo());
  }

  getFeatures(): void {
    this.featuresSubscription = this.videoService.getAllFeatures()
        .subscribe(features => this.features = features,
          () => {},
          () => this.getVideo());
  }
}
