import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { VideoComponent } from './components/video/video.component';
import { FeaturesComponent } from './components/features/features.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PhotoComponent } from './components/photo/photo.component';
import { GalleryComponent } from './components/gallery/gallery.component';

const routes: Routes = [
  {path: '', component: HomeComponent, data: {animation: 'PreviousC'}},
  {path: 'video/:id', component: VideoComponent, data: {animation: 'PreviousA'}},
  {path: 'features/:id', component: VideoComponent, data: {animation: 'PreviousA'}},
  {path: 'photo', component: PhotoComponent, data: {animation: 'PreviousA'}},
  {path: 'features', component: FeaturesComponent, data: {animation: 'PreviousA'}},
  {path: 'photo/:id', component: GalleryComponent, data: {animation: 'PreviousA'}},
  {path: 'contacts', component: ContactsComponent, data: {animation: 'PreviousA'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
