import { Component, OnInit, Input, AfterViewChecked, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AboutService } from 'src/app/services/about.service';
import { About } from 'src/app/models/About';
import { Location } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {
  contactsPage = null;
  id = 'Contacts';
  contentLoaded = false;
  contactsSubscription: any;

  constructor(private route: ActivatedRoute,
    private title: Title,
    private aboutService: AboutService) { }

  ngOnInit() {
    this.getContactsPage();
    this.title.setTitle('Contacts');
  }

  ngAfterViewInit() {
    this.contentLoaded = true;
  }

  ngOnDestroy() {
    this.contactsSubscription.unsubscribe();
  }

  getContactsPage(): void {
    this.contactsSubscription = this.aboutService.getContacts(this.id)
      .subscribe(page => this.contactsPage = page);
  }

}
