import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer, AfterViewInit, OnDestroy } from '@angular/core';
import { Video } from 'src/app/models/Video';
import { Social } from 'src/app/models/Social';
import { Feature } from 'src/app/models/Feature';
import { VideosService } from 'src/app/services/videos.service';
import { SocialService } from 'src/app/services/social.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  videos: Video[];
  socialNetworks: Social[];
  features: Feature[];
  previewList: any;

  featuresList = false;
  commercialsList = false;
  videosList = false;

  videoSubscription: any;
  featuresSubscription: any;
  socialNetworksSubscription: any;

  contentLoaded = false;

  currentSlide: number;
  currentImage: string;

  swiperConfig: SwiperConfigInterface = {
    direction: 'vertical',
    effect: 'fade',
    speed: 800,
    preventClicks: true,
    allowTouchMove: false,
    autoplay: {
      delay: 3000,
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true
    }
  };

  @ViewChild('nav') nav: ElementRef;
  @ViewChild('header') header: ElementRef;
  @ViewChild(SwiperDirective) slideshow: SwiperDirective;

  constructor(private videosService: VideosService, private socialService: SocialService,
    private render: Renderer, private title: Title) { }

  ngOnInit() {
    this.getVideos();
    this.getFeatures();
    this.getSocialNetworks();
    this.title.setTitle('Nikita Kuzmenko: Director of Photography');
  }

  ngAfterViewInit() {
    this.contentLoaded = true;
  }

  ngOnDestroy() {
    this.videoSubscription.unsubscribe();
    this.featuresSubscription.unsubscribe();
    this.socialNetworksSubscription.unsubscribe();
  }

  showCommercials() {
    this.featuresList = false;
    this.commercialsList = !this.commercialsList;
    this.videosList = false;
  }

  showVideos() {
    this.featuresList = false;
    this.commercialsList = false;
    this.videosList = !this.videosList;
  }

  showFeatures() {
    this.featuresList = !this.featuresList;
    this.commercialsList = false;
    this.videosList = false;
  }

  getSocialNetworks(): void {
    this.socialNetworksSubscription = this.socialService.getSocial()
        .subscribe(socialNetworks => this.socialNetworks = socialNetworks);
  }

  removeHoveredTitle() {
    const hoveredTitles = document.getElementsByClassName('hoveredTitle');
      [].forEach.call(hoveredTitles, function(el) {
        el.classList.remove('hoveredTitle');
      });
  }

  onSlideChange() {
    this.removeHoveredTitle();
  }

  goToSlide(i, n, event): void {
    this.removeHoveredTitle();
    if (n === 'feature') {
      i = i + this.videos.length;
    }
    this.currentSlide = i;
    this.slideshow.swiper().slideTo(i);
  }

  stopAutoplay(): void {
      this.slideshow.swiper().autoplay.stop();
  }

  resumeAutoplay(): void {
    this.slideshow.swiper().autoplay.start();
  }

  getVideos(): void {
    this.videoSubscription = this.videosService.getAllVideos()
        .subscribe(videos => this.videos = videos);
  }
  getFeatures(): void {
    this.featuresSubscription = this.videosService.getAllFeatures()
        .subscribe(features => this.features = features);
  }
}
