import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { Feature } from 'src/app/models/Feature';
import { VideosService } from 'src/app/services/videos.service';
import { ViewEncapsulation } from '@angular/core';
// import { paginate } from 'node_modules/jw-paginate';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeaturesComponent implements OnInit, AfterViewInit, OnDestroy {
  photos: Feature[];
  pagedItems = [];
  selectedGallery = null;
  pageOfItems: Array<any>;

  contentLoaded = false;

  photosSubscription: any;

  viewportWidth = window.innerWidth;

  constructor(private videosService: VideosService, private title: Title) { }

  @HostListener('window:mousewheel', ['$event'])
  onMousewheel(event) {
    if (this.viewportWidth > 768) {
    event.preventDefault();
    const next = document.getElementById('nextPage');
    const prev = document.getElementById('prevPage');
    const wheelDelta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    if (event.wheelDelta >= 0) {
      prev.click();
    } else {
      next.click();
    }
  }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.viewportWidth = window.innerWidth;
  }

  ngOnInit() {
    this.getFeatures();
    this.title.setTitle('Features');
  }

  ngAfterViewInit() {
    this.contentLoaded = true;
  }

  ngOnDestroy() {
    this.photosSubscription.unsubscribe();
  }

  selectGallery(gallery: Feature, event): void {
    this.selectedGallery = gallery;
    document.getElementById('title').classList.add('showTitle');
    event.target.parentNode.classList.add('activeGallery');
    event.target.parentNode.parentNode.classList.add('selectedGallery');
  }

  deselectGallery(event): void {
    document.getElementById('title').classList.remove('showTitle');
    this.selectedGallery = null;
    event.target.parentNode.parentNode.classList.remove('selectedGallery');
    const selected = document.getElementsByClassName('activeGallery');
    [].forEach.call(selected, function(el) {
      el.classList.remove('activeGallery');
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
    console.log(this.pageOfItems)
  }

  getFeatures(): void {
    this.photosSubscription = this.videosService.getAllFeatures()
        .subscribe(features => this.pagedItems = features);
  }
}
