import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, HostListener, OnDestroy } from '@angular/core';
import { Photo } from 'src/app/models/Photo';
import { PhotoService } from 'src/app/services/photo.service';
import { ViewEncapsulation } from '@angular/core';
// import { paginate } from 'node_modules/jw-paginate';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhotoComponent implements OnInit, AfterViewInit, OnDestroy {
  photos: Photo[];
  pagedItems = [];
  selectedGallery = null;
  pageOfItems: Array<any>;

  contentLoaded = false;

  photosSubscription: any;

  viewportWidth = window.innerWidth;

  constructor(private photoService: PhotoService, private title: Title) { }

  @HostListener('window:mousewheel', ['$event'])
  onMousewheel(event) {
    if (this.viewportWidth > 768) {
    event.preventDefault();
    const next = document.getElementById('nextPage');
    const prev = document.getElementById('prevPage');
    const wheelDelta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    if (event.wheelDelta >= 0) {
      prev.click();
    } else {
      next.click();
    }
  }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.viewportWidth = window.innerWidth;
  }

  ngOnInit() {
    this.getPhotos();
    this.title.setTitle('Photography');
  }

  ngAfterViewInit() {
    this.contentLoaded = true;
  }

  ngOnDestroy() {
    this.photosSubscription.unsubscribe();
  }

  selectGallery(gallery: Photo, event): void {
    this.selectedGallery = gallery;
    document.getElementById('title').classList.add('showTitle');
    event.target.parentNode.classList.add('activeGallery');
    event.target.parentNode.parentNode.classList.add('selectedGallery');
  }

  deselectGallery(event): void {
    document.getElementById('title').classList.remove('showTitle');
    this.selectedGallery = null;
    event.target.parentNode.parentNode.classList.remove('selectedGallery');
    const selected = document.getElementsByClassName('activeGallery');
    [].forEach.call(selected, function(el) {
      el.classList.remove('activeGallery');
    });
  }

  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  getPhotos(): void {
    this.photosSubscription = this.photoService.getAllPhotos()
        .subscribe(photos => this.pagedItems = photos);
  }

}
