import { Injectable } from '@angular/core';
import { Photo } from '../models/Photo';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  photosUrl = '/.netlify/functions/getPhotos';
  photosArray: any;

  constructor(private http: HttpClient) { }

  getAllPhotos(): Observable<Photo[]> {
    this.photosArray = this.http.get<Photo[]>(this.photosUrl, {
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return this.photosArray;
  }

  getGallery(id: string): Observable<Photo> {
    return this.getAllPhotos().pipe(map(galleries => galleries.find(gallery => gallery.id.current === id)));
  }
}
