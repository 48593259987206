import { Injectable } from '@angular/core';
import { Video } from '../models/Video';
import { Feature } from '../models/Feature';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VideosService {
  videosUrl = '/.netlify/functions/getVideos';
  featuresUrl = '/.netlify/functions/getFeatures';
  videoArray: any;
  featuresArray: any;

  constructor(private http: HttpClient) { }

  getAllVideos(): Observable<Video[]> {
    this.videoArray = this.http.get<Video[]>(this.videosUrl, {
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return this.videoArray;
  }

  getAllFeatures(): Observable<Feature[]> {
    this.featuresArray = this.http.get<Feature[]>(this.featuresUrl, {
      headers: {
        'Content-Type': 'application/json'
      },
    });
    return this.featuresArray;
  }

  getFeature(id: string): Observable<Feature> {
    return this.getAllFeatures().pipe(map(features => features.find(feature => feature.id.current === id)));
  }
}
